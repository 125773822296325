import React from "react"
import "./DemoModal.css"

const DemoModal = () => {
  return (
    <>
      <div
        className="modal fade"
        id="exampleModal"
        tabIndex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="modalTitle">
                Are you a Cisco Meraki customer?
              </h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              {" "}
              <div className="container" id="modalBodyTitleText">
                <h5>
                  Please confirm that you have access to your Meraki admin
                  dashboard to generate a Meraki API key.
                </h5>
              </div>
              <div className="container" id="modalPContainer">
                <h6>Disclaimer :</h6>
                <p>
                  U-find solution will have need to have access to your Meraki
                  Dashboard with read-only permissions. No write actions will be
                  made, so please make sure to generate API with a user that has
                  Read-Only permissions.
                </p>
                <p>
                  Besides access to Meraki Dashboard API, U-find requests you to
                  enable ScanAPI to send device location information every
                  minute. To setup U-find platform and trial you need to have
                  Meraki dashboard administrator rights in order to enable
                  ScanAPI.
                </p>
                <p>
                  After the U-find setup, all the information about users,
                  devices and device location will be shared with U-find for the
                  trial period. No correlation between users and devices is
                  kept, only used for Real Time information if you wish to
                  enable it, keeping GDPR rules of anonymisation and end-user
                  protection.
                </p>
              </div>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary"
                data-bs-dismiss="modal"
                id="modalBtnNo"
              >
                Cancel
              </button>
              <button
                // type="button"
                className="btn btn-primary"
                id="modalBtnYes"
              >
                <a
                  href="https://app.u-find.me/trial"
                  target="_blank"
                  rel="noopener noreferrer"
                  id="anchorModalLink"
                >
                  Confirm
                </a>
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default DemoModal
