import React from "react"
import "./Footer.css"
import { Link } from "gatsby"
// import { AnchorLink } from "gatsby-plugin-anchor-links"
import ufindLogoWhite from "../../images/logoWhite - Optimized.png"
import { ImFacebook } from "react-icons/im"
import { AiFillLinkedin } from "react-icons/ai"
import { AiOutlineTwitter } from "react-icons/ai"

const Footer = () => {
  return (
    <>
      <div className="container-fluid" id="footerContainer">
        <div className="row" id="footerTextArea">
          <div className="col-lg-4" id="footerCol1">
            <img
              src={ufindLogoWhite}
              width="120"
              height="40"
              style={{
                objectFit: "contain",
              }}
              className="d-inline-block align-top"
              alt="React Bootstrap logo"
              id="logoWhite"
            />
            <p className="footerP1">
              Our team is young and eager to tackle any <br /> customer problem
              or requirement. We have a <br /> "Uman" touch approach to all
              problems and we <br /> use technology to find the best solution.
            </p>
            <div className="iconsDiv">
              <a
                href="https://www.facebook.com/ufindio"
                target="_blank"
                rel="noopener noreferrer"
              >
                <div className="socialMediaIcon">
                  <ImFacebook size={20} />
                </div>
              </a>
              <a
                href="https://www.linkedin.com/company/ufindio"
                target="_blank"
                rel="noopener noreferrer"
              >
                <div className="socialMediaIcon">
                  {" "}
                  <AiFillLinkedin size={20} />
                </div>
              </a>
              <a
                href="https://twitter.com/ufindio?t=mk_wDBni4_o4hmMI3Y9ABw&s=09"
                target="_blank"
                rel="noopener noreferrer"
              >
                <div className="socialMediaIcon">
                  {" "}
                  <AiOutlineTwitter size={20} />
                </div>{" "}
              </a>
            </div>
          </div>
          <div className="col-lg-4" id="footerCol2">
            <h5 className="footerH5">Contact</h5>
            <p className="footerP2">
              U-Find.me <br />
              StartUP Alentejo <br />
              Edifício StartUP Alentejo
              <br /> Av. 25 de Abril <br />
              7080-134 Vendas Novas <br />
              Portugal
              <br />
              <br /> Email:&nbsp;{" "}
              <a
                href="mailto: info@u-find.me"
                style={{ textDecoration: "none" }}
                className="emailSpan"
              >
                info@u-find.me
              </a>
            </p>
          </div>
          <div className="col-lg-4" id="footerCol3">
            <h5 className="footerH5">Support & Privacy Policy</h5>
            <Link to="/support" className="footerP3">
              Support guide
            </Link>
            <br />
            <Link to="/privacy" className="footerP3">
              Privacy Policy
            </Link>
          </div>
        </div>
      </div>
      <footer className="footerBar">© {new Date().getFullYear()} U-Find</footer>
    </>
  )
}

export default Footer
