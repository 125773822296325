import React from "react"
import { Navbar, Container, Nav, NavDropdown } from "react-bootstrap"
// import { AnchorLink } from "gatsby-plugin-anchor-links"
import "./NavbarTop.css"
import ufindLogo from "../../images/ufind-logo (1) Optimized.png"
// import { Button, Modal } from "react-bootstrap"
import { Link } from "gatsby"
import DemoModal from "../DemoModal/DemoModal"

const NavbarTop = () => {
  // const [show, setShow] = useState(false)

  // const handleClose = () => setShow(false)
  // const handleShow = () => setShow(true)

  return (
    <>
      <div className="betaTopBar1" id="betaTopBarContainer">
        <div className="betaTopBar2" id="home">
          {" "}
          <div className="container-fluid">
            <div className="betaDiv">
              <p className="betaTopBarParag">Try U-find</p>

              <p
                className="betaTopBarBeta"
                // onClick={handleShow}
                type="button"
                // className="btn btn-primary"
                data-bs-toggle="modal"
                data-bs-target="#exampleModal"
              >
                beta
              </p>
              <DemoModal />
              {/* <Modal
                show={show}
                onHide={handleClose}
                size="lg"
                className="custom-modal-style"
              >
                <Modal.Header closeButton className="modalHeaderH1">
                  <Modal.Title className="modalTitle">
                    Are you a Cisco Meraki customer?
                  </Modal.Title>
                </Modal.Header>
                <Modal.Body className="modalBodyText">
                  <div className="container" id="modalBodyTitleText">
                    <h5>
                      Please confirm that you have access to your Meraki admin
                      dashboard to generate a Meraki API key.
                    </h5>
                  </div>
                  <div className="container" id="modalPContainer">
                    <h6>Disclaimer :</h6>
                    <p>
                      U-find solution will have need to have access to your
                      Meraki Dashboard with read-only permissions. No write
                      actions will be made, so please make sure to generate API
                      with a user that has Read-Only permissions.
                    </p>
                    <p>
                      Besides access to Meraki Dashboard API, U-find requests
                      you to enable ScanAPI to send device location information
                      every minute. To setup U-find platform and trial you need
                      to have Meraki dashboard administrator rights in order to
                      enable ScanAPI.
                    </p>
                    <p>
                      After the U-find setup, all the information about users,
                      devices and device location will be shared with U-find for
                      the trial period. No correlation between users and devices
                      is kept, only used for Real Time information if you wish
                      to enable it, keeping GDPR rules of anonymisation and
                      end-user protection.
                    </p>
                  </div>
                </Modal.Body>
                <Modal.Footer className="modalFooter">
                  <Button
                    variant="secondary"
                    onClick={handleClose}
                    className="modalBtnNo"
                  >
                    Cancel
                  </Button>
                  <Button
                    variant="primary"
                    onClick={handleClose}
                    className="modalBtnYes"
                    href="https://app.u-find.me/trial"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Confirm
                  </Button>
                </Modal.Footer>
              </Modal> */}
            </div>
          </div>
        </div>
      </div>

      <Navbar sticky="top" bg="light" expand="lg" className="navbarTop">
        <div className="container-fluid">
          <Navbar.Brand href="/">
            <img
              src={ufindLogo}
              className="d-inline-block align-top"
              alt="React Bootstrap logo"
              id="ufindLogoNav"
            />
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="navBarWithLinks">
              <Nav.Item className="navItemLink">
                {" "}
                <Link
                  // as={AnchorLink}
                  to="/"
                  className="navLinks"
                  activeClassName="active"
                >
                  Home
                </Link>
              </Nav.Item>
              {/* <NavDropdown
                className="navLinks"
                title="Solutions"
                id="basic-nav-dropdown"
              >
                <NavDropdown.Item
                  className="dropdownLinks"
                  as={AnchorLink}
                  to="/office"
                >
                  Office
                </NavDropdown.Item>
                <NavDropdown.Item
                  as={AnchorLink}
                  to="/shop"
                  className="dropdownLinks"
                >
                  Shop
                </NavDropdown.Item>
              </NavDropdown> */}
              <Nav.Item className="navItemLink">
                <Link
                  // href="services"
                  to="/services"
                  className="navLinks"
                  activeClassName="active"
                >
                  Solutions
                </Link>
              </Nav.Item>
              {/* <Nav.Link className="navLinks" href="#link">
                Partnerships
              </Nav.Link>
              <Nav.Link className="navLinks" href="#link">
                News
              </Nav.Link> */}
              <Nav.Item className="navItemLink">
                <Link
                  // href="services"
                  to="/partner"
                  className="navLinks"
                  activeClassName="active"
                >
                  Partner Program
                </Link>
              </Nav.Item>
              <Nav.Item className="navItemLink">
                <Link
                  // as={AnchorLink}
                  to="/#aboutUsSection"
                  className="navLinks"
                  activeClassName="active"
                >
                  About us
                </Link>
              </Nav.Item>
              <Nav.Item className="navItemLink">
                <Link
                  to="/support"
                  className="navLinks"
                  activeClassName="active"
                >
                  Support
                </Link>
              </Nav.Item>
              {/* <Button className="signInButton" variant="primary">
                Sign In
              </Button> */}
            </Nav>
          </Navbar.Collapse>
        </div>
      </Navbar>

      {/* <nav
        className="navbar navbar-expand-lg navbar-light bg-light sticky-top"
        id="navbarTop"
      >
        <div className="container-fluid">
          <Link className="navbar-brand" to="/">
            <img
              src={ufindLogo}
              className="d-inline-block align-top"
              alt="React Bootstrap logo"
              id="ufindLogoNav"
            />
          </Link>
          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button>
          <div className="collapse navbar-collapse" id="navbarSupportedContent">
            <ul className="navbar-nav mb-2 mb-lg-0">
              <li className="nav-item" id="navLinks">
                <Link
                  className="nav-link"
                  aria-current="page"
                  // href="#"
                  as={AnchorLink}
                  to="/"
                >
                  Home
                </Link>
              </li>

              <li className="nav-item dropdown" id="navLinks">
                <a
                  className="nav-link dropdown-toggle"
                  href="#"
                  id="navbarDropdown"
                  role="button"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  Solutions
                </a>
                <ul className="dropdown-menu" aria-labelledby="navbarDropdown">
                  <li className="dropdown-item-li">
                    <a className="dropdown-item" href="office">
                      Office
                    </a>
                  </li>
                  <li className="dropdown-item-li">
                    <a className="dropdown-item" href="shop">
                      Shop
                    </a>
                  </li>
                </ul>
              </li>
              <li className="nav-item" id="navLinks">
                <a className="nav-link" href="#">
                  Partnerships
                </a>
              </li>
              <li className="nav-item" id="navLinks">
                <a className="nav-link" href="#">
                  Customers
                </a>
              </li>
              <li className="nav-item" id="navLinks">
                <a className="nav-link" href="#">
                  News
                </a>
              </li>
              <li className="nav-item" id="navLinks">
                <Link
                  className="nav-link"
                  as={AnchorLink}
                  to="/#aboutUsSection"
                >
                  {" "}
                  About us
                </Link>
              </li>
            </ul>
            <Button className="signInButton" variant="primary">
              Sign In
            </Button>
          </div>
        </div>
      </nav> */}
    </>
  )
}

export default NavbarTop
